<template>
  <div class="markup-tables flex">
    <va-card class="flex xs12 mb-4" v-if="file">
      <va-card-title style="font-size: 1.5rem;">
        {{ file.name }}
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; color: grey;" v-if="downloadInProgress && file.type" />
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; cursor: pointer;" @click="downloadFiles" v-else-if="file.type" />
        <va-button size="small" class="mr-2 mb-2" color="success" style="margin-left: 1rem; margin-bottom: 0 !important; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#configureFileModal" v-if="file.type === 'mov'">Configure</va-button>
        <div class="spinner-border" v-if="downloadInProgress"></div>
      </va-card-title>
    </va-card>

    <div class="row" style="margin-bottom: 1.5rem; margin-left: 0.25rem; margin-right: 0.25rem;" v-if="file && file.type && file.type === 'mov'">
      <va-card class="flex xs12 md3" style="min-height: 600px;" v-if="fileLink && isMobile">
        <va-card-title style="padding: .75rem .75rem;">
          <div style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#videoModal">
            <va-icon size="12px" :name="'fa4-expand'" />&nbsp;&nbsp;Full Screen
          </div>
        </va-card-title>
        <va-card-content>
          <video id="fileVideo" @play="videoPlayPause(true)" @pause="videoPlayPause(false)" style="max-height: 100%; max-width: 78%;" controls>
            <source :src="fileLink.media_mp4+'#t=0.001'" type="video/mp4">
            <source :src="fileLink.media+'#t=0.001'" type="video/mp4">
          </video>
        </va-card-content>
      </va-card>
      <va-card class="flex xs12 md3" v-else-if="fileLink">
        <va-card-title style="padding: .75rem .75rem;">
          <div style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#videoModal">
            <va-icon size="12px" :name="'fa4-expand'" />&nbsp;&nbsp;Full Screen
          </div>
        </va-card-title>
        <va-card-content>
          <video id="fileVideo" @play="videoPlayPause(true)" @pause="videoPlayPause(false)" style="width: auto; max-height: 100%; max-width: 78%; left: 50%; transform: translateX(-50%);" controls>
            <source :src="fileLink.media_mp4" type="video/mp4">
            <source :src="fileLink.media" type="video/mp4">
          </video>
        </va-card-content>
      </va-card>
      <va-card class="flex xs12 md4 offset--md1">
        <apexchart type="line" height="350" :options="videoChartOptions" :series="videoSeries" v-if="videoChart"></apexchart>
        <apexchart type="line" height="350" :options="chartOptions" :series="series" @markerClick="markerClickHandler" v-else></apexchart>
      </va-card>
      <va-card class="flex xs12 md3 offset--md1">
        <va-card-content>
          <GMapMap style="width: 100%; height: 280px;" :center="center" :zoom="15" :options="options" ref="gMap">
            <!-- Remove other pointes -->
            <!--
            <GMapMarker
              v-for="stat in markers"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            -->
            <GMapMarker
              v-for="stat in selectedItems"
              :icon="require('@/assets/google-icon/man.png')"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            <GMapMarker
              v-if="mapPoint"
              :icon="require('@/assets/google-icon/man.png')"
              :position="{ lat: parseFloat(mapPoint.lat), lng: parseFloat(mapPoint.lon) }"
            />
            <GMapMarker
              v-if="lastPoint"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
            />
            <GMapInfoWindow
              v-for="stat in infoWindows"
              :closeclick="true"
              :opened="true"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              >
              {{ stat.address }}
            </GMapInfoWindow>
            <GMapMarker v-for="stat in infoWindows"
              :key="stat.uid"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              @click="openMarker(stat.uid)"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === stat.uid"
                >
                <div id="contet">
                  <div id="siteNotice"></div>
                  <h1 id="firstHeading" class="firstHeading">Address</h1>
                  <div id="bodyContent">
                    <p>
                      {{ stat.address }}
                    </p>
                  </div>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapPolyline :options="directionPath" :path="directionPath.path" v-if="directionPath" />
            <GMapPolyline :options="directionPathCourse" :path="directionPathCourse.path" v-if="directionPathCourse" />
            <GMapPolyline :options="flightPath" :path="flightPath.path" />
          </GMapMap>
        </va-card-content>
      </va-card>
      <va-card class="flex xs12 md12" style="margin-top: 1.5rem;" v-if="fileLink && (file.splats_status === 'completed' || file.splats_status === 'processing')">
        <va-card-title style="padding: .75rem .75rem; font-size: 1.5rem;">
          Splats Model
        </va-card-title>
        <va-card-content>
          <ply-view :plyUrl="file.splats_status === 'completed' ? fileLink.splat_30k : fileLink.splat" />
        </va-card-content>
      </va-card>
    </div>
    <div class="row" style="margin-bottom: 1.5rem; margin-left: 0.25rem; margin-right: 0.25rem;" v-if="file && file.type && (file.type === 'jpg' || file.type === 'jpeg')">
      <va-card class="flex xs12 md4 offset--md1" v-if="fileLink">
        <va-card-content>
          <div v-viewer>
            <img
              :src="fileLink.media" style="width: 100%; cursor: pointer;"
              alt="Image"
            >
          </div>
        </va-card-content>
      </va-card>
      <va-card class="flex xs12 md4 offset--md1">
        <va-card-content>
          <GMapMap style="width: 100%; height: 280px;" :center="center" :zoom="15" :options="options" ref="gMap">
            <!-- Remove other pointes -->
            <!--
            <GMapMarker
              v-for="stat in markers"
              :icon:"'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            -->
            <GMapMarker
              v-for="stat in selectedItems"
              :icon="require('@/assets/google-icon/man.png')"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            <GMapMarker
              v-if="lastPoint"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
            />
            <GMapInfoWindow
              v-for="stat in infoWindows"
              :closeclick="true"
              :opened="true"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              >
              {{ stat.address }}
            </GMapInfoWindow>
            <GMapMarker v-for="stat in infoWindows"
              :key="stat.uid"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              @click="openMarker(stat.uid)"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === stat.uid"
                >
                <div id="contet">
                  <div id="siteNotice"></div>
                  <h1 id="firstHeading" class="firstHeading">Address</h1>
                  <div id="bodyContent">
                    <p>
                      {{ stat.address }}
                    </p>
                  </div>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapPolyline :options="directionPath" :path="directionPath.path" v-if="directionPath" />
            <GMapPolyline :options="directionPathCourse" :path="directionPathCourse.path" v-if="directionPathCourse" />
            <GMapPolyline :options="flightPath" :path="flightPath.path" />
          </GMapMap>
        </va-card-content>
      </va-card>
    </div>
    <div class="row" style="margin-bottom: 1.5rem; margin-left: 0.25rem; margin-right: 0.25rem;" v-if="file && file.type && file.type === 'obj'">
      <va-card class="flex xs12 md7">
        <va-card-title>
          <div class="row">
            <div class="flex xs1 md4 offset--md8" style="text-align: right;">
              <va-dropdown
                v-model="showSettingsDropdown"
                :close-on-click-outside="false"
                :close-on-content-click="false"
              >
                <template #anchor>
                  <span style="font-size: 1rem; cursor: pointer;" class="download-icon">
                    <span>Model Settings</span>
                    <va-icon :name="'fa4-cog'" style="padding-left: .5rem;" />
                  </span>
                </template>

                <va-dropdown-content>
                  <div class="row" style="width: 25rem;">
                    <div class="flex xs12 md12">
                      <table>
                        <tbody>
                          <tr>
                            <td class="setting-dropdown-row">Show Edges</td>
                            <td class="setting-dropdown-row">
                              <va-switch
                                v-model="showEdge"
                                size="small"
                              />
                            </td>
                          </tr>
                          <tr class="setting-dropdown-row">
                            <td class="setting-dropdown-row">Background Color</td>
                            <td class="setting-dropdown-row">
                              <va-color-input
                                v-model="backgroundColor"
                                label="Background Color"
                                input-aria-label="Background Color"
                                inner-label="Background Color"
                                aria-open-color-picker-label="Background Color"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="flex xs16 md6 offset--md4 setting-dropdown-row">
                      <va-button
                        class="xs12 md12"
                        @click="applySettings"
                      >
                        Apply
                      </va-button>
                    </div>
                  </div>
                </va-dropdown-content>
              </va-dropdown>
            </div>
          </div>
        </va-card-title>
        <va-content>
          <div id="viewer" style="width: 100%; height: 600px; cursor: pointer;">
          </div>
          <!-- Loader -->
          <loading v-model:active="modelLoading"
            :opacity="1"
            :can-cancel="false"
            :is-full-page="false"/>
        </va-content>
      </va-card>
      <va-card class="flex xs12 md4 offset--md1" style="height: 350px;">
        <va-card-content>
          <GMapMap style="width: 100%; height: 280px;" :center="center" :zoom="15" :options="options" ref="gMap">
            <!-- Remove other pointes -->
            <!--
            <GMapMarker
              v-for="stat in markers"
              :icon:"'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            -->
            <GMapMarker
              v-for="stat in selectedItems"
              :icon="require('@/assets/google-icon/man.png')"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            <GMapMarker
              v-if="lastPoint"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
            />
            <GMapInfoWindow
              v-for="stat in infoWindows"
              :closeclick="true"
              :opened="true"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              >
              {{ stat.address }}
            </GMapInfoWindow>
            <GMapMarker v-for="stat in infoWindows"
              :key="stat.uid"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              @click="openMarker(stat.uid)"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === stat.uid"
                >
                <div id="contet">
                  <div id="siteNotice"></div>
                  <h1 id="firstHeading" class="firstHeading">Address</h1>
                  <div id="bodyContent">
                    <p>
                      {{ stat.address }}
                    </p>
                  </div>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapPolyline :options="directionPath" :path="directionPath.path" v-if="directionPath" />
            <GMapPolyline :options="directionPathCourse" :path="directionPathCourse.path" v-if="directionPathCourse" />
            <GMapPolyline :options="flightPath" :path="flightPath.path" />
          </GMapMap>
        </va-card-content>
      </va-card>
    </div>
    <div class="row" style="margin-bottom: 1.5rem; margin-left: 0.25rem; margin-right: 0.25rem;" v-if="file && !file.type">
      <va-card class="flex xs12 md4 offset--md1">
        <apexchart type="line" height="350" :options="videoChartOptions" :series="videoSeries" v-if="playChart"></apexchart>
        <apexchart type="line" height="350" :options="chartOptions" :series="series" @markerClick="markerClickHandler" v-else></apexchart>
        <va-button class="mr-2 mb-2" icon-right="fa4-stop" @click="playPauseChart(false)" v-if="playChart">
          Stop&nbsp;&nbsp;
        </va-button>
        <va-button class="mr-2 mb-2" icon-right="fa4-play" @click="playPauseChart(true)" v-else>
          Simulate&nbsp;&nbsp;
        </va-button>
      </va-card>
      <va-card class="flex xs12 md4 offset--md1">
        <va-card-content>
          <GMapMap style="width: 100%; height: 280px;" :center="center" :zoom="15" :options="options" ref="gMap">
            <!-- Remove other pointes -->
            <!--
            <GMapMarker
              v-for="stat in markers"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            -->
            <GMapMarker
              v-for="stat in selectedItems"
              :icon="require('@/assets/google-icon/man.png')"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            <GMapMarker
              v-if="mapPoint"
              :icon="require('@/assets/google-icon/man.png')"
              :position="{ lat: parseFloat(mapPoint.lat), lng: parseFloat(mapPoint.lon) }"
            />
            <GMapMarker
              v-if="lastPoint"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
            />
            <GMapInfoWindow
              v-for="stat in infoWindows"
              :closeclick="true"
              :opened="true"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              >
              {{ stat.address }}
            </GMapInfoWindow>
            <GMapMarker v-for="stat in infoWindows"
              :key="stat.uid"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              @click="openMarker(stat.uid)"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === stat.uid"
                >
                <div id="contet">
                  <div id="siteNotice"></div>
                  <h1 id="firstHeading" class="firstHeading">Address</h1>
                  <div id="bodyContent">
                    <p>
                      {{ stat.address }}
                    </p>
                  </div>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapPolyline :options="directionPath" :path="directionPath.path" v-if="directionPath" />
            <GMapPolyline :options="directionPathCourse" :path="directionPathCourse.path" v-if="directionPathCourse" />
            <GMapPolyline :options="flightPath" :path="flightPath.path" />
          </GMapMap>
        </va-card-content>
      </va-card>
    </div>

    <div class="modal fade" id="photoModal" tabindex="-1" aria-labelledby="photoModal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content" style="background-color: #000000a6;">
          <div class="modal-header" style="border-bottom: 0;">
            <va-button class="mr-2 mb-2 btn-close" icon="ion_md_close" data-bs-dismiss="modal" />
          </div>
          <div class="modal-body flex-center">
            <img style="height: 100%;" :src="fileLink.media" v-if="fileLink" />
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content" style="background-color: #000000a6;">
          <div class="modal-header" style="border-bottom: 0;">
            <va-button class="mr-2 mb-2 btn-close" icon="ion_md_close" data-bs-dismiss="modal" />
          </div>
          <div class="modal-body flex-center">
            <video id="fileVideo" @play="videoPlayPause(true)" @pause="videoPlayPause(false)" style="width: auto; max-height: 100%;" controls v-if="fileLink">
              <source :src="fileLink.media_mp4" type="video/mp4">
              <source :src="fileLink.media" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </div>

    <va-card class="flex xs12 mb-4" v-if="file && file.note && file.note.length > 0">
      <va-card-title style="font-size: 1.5rem;">Note</va-card-title>
      <va-card-content>
        <div class="flex md12">
          {{ file.note }}
        </div>
      </va-card-content>
    </va-card>

    <va-card class="flex xs12 mb-4" v-if="file && stats">
      <va-card-title style="font-size: 1.5rem;">
        Statistics
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; cursor: pointer;" @click="downloadCSV" />
      </va-card-title>
      <va-card-content>
        <va-data-table
          v-if="!file.type || file.type === 'mov'"
          :items="stats"
          :columns="columns"
          :select-mode="'single'"
          :selected-color="'primary'"
          :per-page="perPage"
          :current-page="currentPage"
          v-model="selectedItems"
          selectable
          hoverable
        >
          <template #bodyAppend>
            <tr>
              <td colspan="10" class="table-example--pagination">
                <va-pagination
                  v-model="currentPage"
                  input
                  :pages="pages"
                />
              </td>
              <td colspan="6" class="table-example--pagination">
                <va-select
                  style="width: 8rem;"
                  v-model="perPage"
                  :label="'Per Page'"
                  :options="perPageOptions"
                  noClear
                />
              </td>
            </tr>
          </template>
        </va-data-table>
        <va-data-table
          v-else
          :items="stats"
          :columns="columns"
        />
      </va-card-content>
    </va-card>
    <div class="modal fade" id="configureFileModal" tabindex="-1" aria-labelledby="configureFileModal" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0;">
            Configure File
          </div>
          <div class="modal-body" v-if="file">
            <div class="md-12 configure-md row">
              <div class="md-12">
                <va-checkbox v-model="splats_status" label="Convert to Splats" :disabled="file.splats_status !== 'na' && file.splats_status !== 'failed'" />
              </div>
              <div class="md-6">
                <va-progress-circle size="small" indeterminate v-if="file.splats_status !== 'na' && file.splats_status !== 'completed' && file.splats_status !== 'processing' && file.splats_status !== 'failed'" />
                <va-chip size="small" :color="'success'" v-if="file.splats_status === 'completed'">Completed</va-chip>
                <va-chip size="small" :color="'warning'" v-if="file.splats_status === 'processing'">Processed 7k</va-chip>
                <va-chip size="small" :color="'danger'" v-else-if="file.splats_status === 'failed'">Failed</va-chip>
                <va-icon :name="'fa4-refresh'" class="download-icon" style="padding-left: .5rem; cursor: pointer;" @click="retryConfigure('splats_status')" v-if="file.splats_status === 'completed'" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <va-button class="mr-2 mb-2" color="gray" data-bs-dismiss="modal">Cancel</va-button>
            <va-button class="mr-2 mb-2" color="primary" @click="configureFile" data-bs-dismiss="modal">Save</va-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue3-apexcharts'
import { isMobile } from 'mobile-device-detect'
import JSZip from 'jszip'
import { saveAs } from 'save-as'
import * as OV from 'online-3d-viewer'
import VueLoading from 'vue-loading-overlay'
import Ply from './ply/View'

export default {
  components: {
    'apexchart': VueApexCharts,
    loading: VueLoading,
    'ply-view': Ply
  },
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load file
      const file = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files/${this.fileId}`, { headers })
      this.file = file.data.file
      this.stats = this.file.stats

      if (this.stats.length > 0) {
        this.stats.forEach((stat) => {
          stat.mic = Math.round(Math.pow(10, stat.mic) * 10000) / 10000
        })

        this.center = {
          lat: this.stats[0] ? parseFloat(this.stats[0].lat) : 0.000000,
          lng: this.stats[0] ? parseFloat(this.stats[0].lon) : 0.000000
        }

        // If file is a video file, then find the last point
        if (!this.file.type || this.file.type === 'mov') {
          this.lastPoint = {
            lat: parseFloat(this.stats[this.stats.length - 1].lat),
            lng: parseFloat(this.stats[this.stats.length - 1].lon)
          }

          this.selectedItems.push(this.stats[Math.floor(this.stats.length / 2)])
        } else {
          this.selectedItems = [ this.stats[0] ]
        }

        this.markers = []
        this.infoWindows = []
        const flightPlanCoordinates = []
        const flightPlanCoordinatesTracker = []
        this.stats.forEach((stat) => {
          const newCoordinate = {
            lat: parseFloat(stat.lat),
            lng: parseFloat(stat.lon)
          }
          const newCoordinateTracker = `${stat.lat},${stat.lon}`
          if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
            flightPlanCoordinates.push(newCoordinate)
            flightPlanCoordinatesTracker.push(newCoordinateTracker)
          }
          if (stat.address) {
            this.infoWindows.push(stat)
          } else {
            this.markers.push(stat)
          }

          // Fill graph data
          this.series[0].data.push(stat.mic)
          this.series[1].data.push(stat.accelUserX)
          this.series[2].data.push(stat.accelUserY)
          this.series[3].data.push(stat.accelUserZ)
          this.series[4].data.push(stat.gyroX)
          this.series[5].data.push(stat.gyroY)
          this.series[6].data.push(stat.gyroZ)
          this.series[7].data.push(stat.speed)
          this.chartOptions.xaxis.categories.push(stat.timestamp)
        })

        // Find min and max values
        const minMax = []
        minMax.push(Math.min(...this.series[0].data))
        minMax.push(Math.max(...this.series[0].data))
        minMax.push(Math.min(...this.series[1].data))
        minMax.push(Math.max(...this.series[1].data))
        minMax.push(Math.min(...this.series[2].data))
        minMax.push(Math.max(...this.series[2].data))
        minMax.push(Math.min(...this.series[3].data))
        minMax.push(Math.max(...this.series[3].data))
        minMax.push(Math.min(...this.series[4].data))
        minMax.push(Math.max(...this.series[4].data))
        minMax.push(Math.min(...this.series[5].data))
        minMax.push(Math.max(...this.series[5].data))
        minMax.push(Math.min(...this.series[6].data))
        minMax.push(Math.max(...this.series[6].data))
        minMax.push(Math.min(...this.series[7].data))
        minMax.push(Math.max(...this.series[7].data))

        this.videoChartOptions.yaxis.min = Math.min(...minMax)
        this.videoChartOptions.yaxis.max = Math.max(...minMax)

        if (this.infoWindows.length > 0) {
          const tempMarkers = []
          this.markers.forEach((marker) => {
            if (marker.lat !== this.infoWindows[0].lat || marker.lon !== this.infoWindows[0].lon) {
              tempMarkers.push(marker)
            }
          })
          this.markers = tempMarkers
        }

        this.flightPath = {
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: '#00FF00',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }
      }

      // Get file links
      const fileLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files/${this.fileId}/download`, { headers })
      this.fileLink = fileLink.data.file
      this.images.push({
        thumbnail: this.fileLink.media,
        source: this.fileLink.media,
        title: 'JPG'
      })

      this.$refs.gMap.$mapPromise.then(() => {
        this.google = window.google
        this.drawArrow()
      })
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files/${this.fileId}` } })
    }
    this.loader.hide()

    setTimeout(() => {
      const fileVideo = document.getElementById('fileVideo')
      if (fileVideo) {
        fileVideo.addEventListener('timeupdate', this.setMap)
      }
    }, 2000)

    if (this.file.type === 'obj') {
      await this.show3D()
    }
  },
  data () {
    return {
      isMobile,
      loader: null,
      token: this.$store.state.token,
      downloadInProgress: false,
      session: {},
      fileLink: null,
      file: null,
      id: this.$route.params.id,
      fileId: this.$route.params.fileId,
      stats: null,
      infoWindows: [],
      markers: [],
      center: null,
      lastPoint: null,
      flightPath: {},
      selectedItems: [],
      mapPoint: null,
      directionPath: null,
      directionPathCourse: null,
      perPage: 5,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      columns: [
        {
          key: 'lat',
          label: 'Lat',
          headerTitle: 'Lat'
        },
        {
          key: 'lon',
          label: 'Long',
          headerTitle: 'Long'
        },
        {
          key: 'accelUserX',
          label: 'accelUserX',
          headerTitle: 'accelUserX'
        },
        {
          key: 'accelUserY',
          label: 'accelUserY',
          headerTitle: 'accelUserY'
        },
        {
          key: 'accelUserZ',
          label: 'accelUserZ',
          headerTitle: 'accelUserZ'
        },
        {
          key: 'gyroX',
          label: 'gyroX',
          headerTitle: 'gyroX'
        },
        {
          key: 'gyroY',
          label: 'gyroY',
          headerTitle: 'gyroY'
        },
        {
          key: 'gyroZ',
          label: 'gyroZ',
          headerTitle: 'gyroZ'
        },
        {
          key: 'pitch',
          label: 'Pitch',
          headerTitle: 'Pitch'
        },
        {
          key: 'heading',
          label: 'Heading',
          headerTitle: 'Heading'
        },
        {
          key: 'speed',
          label: 'Speed',
          headerTitle: 'Speed'
        },
        {
          key: 'course',
          label: 'Course',
          headerTitle: 'Course'
        },
        {
          key: 'mic',
          label: 'Mic',
          headerTitle: 'Mic'
        },
        {
          key: 'pressure',
          label: 'Pressure',
          headerTitle: 'Pressure'
        },
        {
          key: 'calMagX',
          label: 'CalMagX',
          headerTitle: 'CalMagX'
        },
        {
          key: 'calMagY',
          label: 'CalMagY',
          headerTitle: 'CalMagY'
        },
        {
          key: 'calMagZ',
          label: 'CalMagZ',
          headerTitle: 'CalMagZ'
        },
        {
          key: 'calMagTotal',
          label: 'CalMagTotal',
          headerTitle: 'CalMagTotal'
        },
        {
          key: 'timestamp',
          label: 'Date/Time',
          headerTitle: 'Date/Time',
          sortable: true
        },
        {
          key: 'timestamp_utc',
          label: 'Date/Time (UTC)',
          headerTitle: 'Date/Time (UTC)',
          sortable: true
        }
      ],
      rows: [],
      google: null,
      series: [
        {
          name: 'MIC',
          data: []
        },
        {
          name: 'ACCELUSERX',
          data: []
        },
        {
          name: 'ACCELUSERY',
          data: []
        },
        {
          name: 'ACCELUSERZ',
          data: []
        },
        {
          name: 'GYROX',
          data: []
        },
        {
          name: 'GYROY',
          data: []
        },
        {
          name: 'GYROZ',
          data: []
        },
        {
          name: 'SPEED',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: true
          },
          foreColor: '#6E8192'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        xaxis: {
          categories: [],
          type: 'datetime'
        },
        colors: ['#0000FF', '#5F0F40', '#00B577', '#FF0025', '#4F399A', '#008DFB', '#006E48', '#888f01']
      },
      videoSeries: [
        {
          name: 'MIC',
          data: []
        },
        {
          name: 'ACCELUSERX',
          data: []
        },
        {
          name: 'ACCELUSERY',
          data: []
        },
        {
          name: 'ACCELUSERZ',
          data: []
        },
        {
          name: 'GYROX',
          data: []
        },
        {
          name: 'GYROY',
          data: []
        },
        {
          name: 'GYROZ',
          data: []
        },
        {
          name: 'SPEED',
          data: []
        }
      ],
      videoChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          zoom: {
            enabled: true
          },
          foreColor: '#6E8192'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        xaxis: {
          categories: [],
          type: 'datetime',
          range: 2000
        },
        yaxis: {},
        colors: ['#0000FF', '#5F0F40', '#00D68D', '#FF0025', '#4F399A', '#1500FB', '#006E48', '#888f01']
      },
      videoChart: false,
      playChart: false,
      playPauseChartTimeout: null,
      openedMarkerID: null,
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#8ec3b9"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1a3646"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#64779e"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#334e87"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#6f9ba5"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#3C7680"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#304a7d"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#2c6675"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#255763"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#b0d5ce"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#3a4762"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#0e1626"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#4e6d70"
              }
            ]
          }
        ]
      },
      images: [],
      showSettingsDropdown: false,
      modelLoading: false,
      viewer: null,
      showEdge: false,
      backgroundColor: '#fff',
      positionX: -1.5,
      positionY: 2.0,
      positionZ: 3.0,
      upX: 0.0,
      upY: 1.0,
      upZ: 0.0,
      fov: 60,
      backgroundColorR: 255,
      backgroundColorG: 255,
      backgroundColorB: 255,
      backgroundColorO: 255,
      splats_status: false
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.stats.length / this.perPage)
        : this.stats.length
    }
  },
  watch: {
    selectedItems () {
      this.drawArrow()
      this.setVideoTime()
    }
  },
  methods: {
    downloadFiles: async function () {
      this.downloadInProgress = true
      this.$vaToast.init({
        message: 'Downloading Files. Please wait.',
        iconClass: 'fa-star-o',
        position: 'bottom-right',
        duration: 6000,
        fullWidth: false,
        color: 'primary',
      })
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Get file links
        const fileLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files/${this.fileId}/download`, { headers })

        if (this.file.type === 'obj') {
          const fileId = this.fileId
          const zip = new JSZip()
          const files = zip.folder(fileId)
          let res = await axios({
            url: fileLink.data.file.obj,
            method: 'GET',
            responseType: 'arraybuffer',
          })
          const objBlob = new Blob([res.data], {
            type: 'model/obj'
          })

          res = await axios({
            url: fileLink.data.file.mtl,
            method: 'GET',
            responseType: 'arraybuffer',
          })
          const mtlBlob = new Blob([res.data], {
            type: 'model/mtl'
          })

          res = await axios({
            url: fileLink.data.file.jpg,
            method: 'GET',
            responseType: 'arraybuffer',
          })
          const jpgBlob = new Blob([res.data], {
            type: 'image/jpg'
          })

          files.file('mesh.obj', objBlob, {base64: true})
          files.file('mesh.mtl', mtlBlob, {base64: true})
          files.file('texture.jpg', jpgBlob, {base64: true})

          zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, `${fileId}.zip`)
          })
        } else if (this.file.type === 'mov' && this.file.splats_status === 'completed') {
          res = await axios({
            url: fileLink.data.file.splat,
            method: 'GET',
            responseType: 'arraybuffer',
          })
          const splatBlob = new Blob([res.data], {
            type: 'application/x-splat'
          })

          res = await axios({
            url: fileLink.data.file.splat_30k,
            method: 'GET',
            responseType: 'arraybuffer',
          })
          const splatTBlob = new Blob([res.data], {
            type: 'application/x-splat'
          })

          res = await axios({
            url: fileLink.data.file.media,
            method: 'GET',
            responseType: 'arraybuffer',
          })
          const videoBlob = new Blob([res.data], {
            type: 'video/mp4'
          })

          files.file('model.splat', splatBlob, {base64: true})
          files.file('model_30k.splat', splatTBlob, {base64: true})
          files.file('video.mp4', videoBlob, {base64: true})

          zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, `${fileId}.zip`)
          })
        } else {
          const res = await axios({
            url: fileLink.data.file.media,
            method: 'GET',
            responseType: 'arraybuffer',
          })
          const blob = new Blob([res.data], {
            type: this.file.type === 'mov' ? 'video/mp4' : `image/${this.file.type}`
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = this.file.type === 'mov' ? `${this.file.name.split('.')[0]}.mov` : `${this.file.name.split('.')[0]}.${this.file.type}`
          link.click()
        }
        this.downloadInProgress = false
      } catch (error) {
        this.downloadInProgress = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files/${this.fileId}` } })
      }
    },
    downloadCSV () {
      this.rows = [
        ['lat', 'lon', 'accelUserX', 'accelUserY', 'accelUserZ', 'gyroX', 'gyroY', 'gyroZ', 'pitch', 'heading', 'speed', 'course', 'mic', 'pressure', 'calMagX', 'calMagY', 'calMagZ', 'calMagTotal', 'timestamp', 'timestamp_utc']
      ]
      this.stats.forEach((stat) => {
        this.rows.push([
          stat.lat, stat.lon, stat.accelUserX, stat.accelUserY, stat.accelUserZ, stat.gyroX, stat.gyroY, stat.gyroZ, stat.pitch, stat.heading, stat.speed, stat.course, stat.mic, stat.pressure, stat.calMagX, stat.calMagY, stat.calMagZ, stat.calMagTotal, stat.timestamp, stat.timestamp_utc
        ])
      })

      const csvContent = 'data:text/csv;charset=utf-8,' + this.rows.map(e => e.join(',')).join('\n')

      const encodedUri = encodeURI(csvContent)

      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${this.file.name.split('.')[0]}.csv`)
      document.body.appendChild(link)

      link.click()
    },
    drawArrow: async function () {
      if (this.selectedItems.length > 0) {
        const pointA = new this.google.maps.LatLng(parseFloat(this.selectedItems[0].lat), parseFloat(this.selectedItems[0].lon))
        const distance = 20 // in meters
        let bearing = parseFloat(this.selectedItems[0].heading)
        let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

        const lineSymbol = {
          path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        }

        this.directionPath = {
          path: [
            {
              lat: pointA.lat(),
              lng: pointA.lng()
            },
            {
              lat: pointB.lat(),
              lng: pointB.lng()
            }
          ],
          icons: [
            {
              icon: lineSymbol,
              offset: '100%',
            },
          ],
          geodesic: true,
          strokeColor: '#FA86C4',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }

        bearing = parseFloat(this.selectedItems[0].course)
        pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

        this.directionPathCourse = {
          path: [
            {
              lat: pointA.lat(),
              lng: pointA.lng()
            },
            {
              lat: pointB.lat(),
              lng: pointB.lng()
            }
          ],
          icons: [
            {
              icon: lineSymbol,
              offset: '100%',
            },
          ],
          geodesic: true,
          strokeColor: '#006721',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }
      }
      else {
        this.directionPath = null
        this.directionPathCourse = null
      }
    },
    setVideoTime: function () {
      if (this.selectedItems.length > 0 && this.file.type !== 'jpg' && this.file.type !== 'jpeg' && this.file.type !== 'obj') {
        const startTime = new Date(this.stats[0].timestamp)
        const endTime = new Date(this.selectedItems[0].timestamp)
        const timeDifference = (endTime.getTime() - startTime.getTime()) / 1000
        document.getElementById('fileVideo').currentTime = timeDifference
      }
    },
    setMap: function () {
      const startTime = new Date(this.stats[0].timestamp)
      const fileVideo = document.getElementById('fileVideo')
      const endTime = startTime.getTime() + (fileVideo.currentTime * 1000)
      const date = new Date(endTime)

      for (let i = 0; i < this.stats.length; i++) {
        if (date < new Date(this.stats[i].timestamp)) {
          if (!this.mapPoint || this.mapPoint.lat !== this.stats[i].lat || this.mapPoint.lon !== this.stats[i].lon) {
            this.mapPoint = this.stats[i]
            const pointA = new this.google.maps.LatLng(parseFloat(this.stats[i].lat), parseFloat(this.stats[i].lon))
            const distance = 20 // in meters
            let bearing = parseFloat(this.stats[i].heading)
            let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            const lineSymbol = {
              path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            }

            this.directionPath = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#FA86C4',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }

            bearing = parseFloat(this.stats[i].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourse = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }

          this.videoSeries[0].data.push(this.stats[i].mic)
          this.videoSeries[1].data.push(this.stats[i].accelUserX)
          this.videoSeries[2].data.push(this.stats[i].accelUserY)
          this.videoSeries[3].data.push(this.stats[i].accelUserZ)
          this.videoSeries[4].data.push(this.stats[i].gyroX)
          this.videoSeries[5].data.push(this.stats[i].gyroY)
          this.videoSeries[6].data.push(this.stats[i].gyroZ)
          this.videoSeries[7].data.push(this.stats[i].speed)
          this.videoChartOptions.xaxis.categories.push(this.stats[i].timestamp)

          break
        }
      }
    },
    videoPlayPause (play) {
      if (play) {
        this.videoSeries[0].data = []
        this.videoSeries[1].data = []
        this.videoSeries[2].data = []
        this.videoSeries[3].data = []
        this.videoSeries[4].data = []
        this.videoSeries[5].data = []
        this.videoSeries[6].data = []
        this.videoSeries[7].data = []
        this.videoChartOptions.xaxis.categories = []

        this.videoChart = true
      } else {
        this.videoChart = false
      }
    },
    markerClickHandler (event, chartContext, { seriesIndex, dataPointIndex, config }) {
      // Set Map point
      this.mapPoint = this.stats[dataPointIndex]
      const pointA = new this.google.maps.LatLng(parseFloat(this.stats[dataPointIndex].lat), parseFloat(this.stats[dataPointIndex].lon))
      const distance = 20 // in meters
      let bearing = parseFloat(this.stats[dataPointIndex].heading)
      let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

      const lineSymbol = {
        path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      }

      this.directionPath = {
        path: [
          {
            lat: pointA.lat(),
            lng: pointA.lng()
          },
          {
            lat: pointB.lat(),
            lng: pointB.lng()
          }
        ],
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#FA86C4',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }

      bearing = parseFloat(this.stats[dataPointIndex].course)
      pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

      this.directionPathCourse = {
        path: [
          {
            lat: pointA.lat(),
            lng: pointA.lng()
          },
          {
            lat: pointB.lat(),
            lng: pointB.lng()
          }
        ],
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#006721',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }

      // Set Video time
      const fileVideo = document.getElementById('fileVideo')
      if (fileVideo) {
        const startTime = new Date(this.stats[0].timestamp)
        const endTime = new Date(this.stats[dataPointIndex].timestamp)
        const timeDifference = (endTime.getTime() - startTime.getTime()) / 1000
        fileVideo.currentTime = timeDifference
      }
    },
    playPauseChart (play) {
      if (play) {
        this.playChart = true

        let i = 0

        this.playPauseChartTimeout = setInterval(() => {
          if (!this.mapPoint || this.mapPoint.lat !== this.stats[i].lat || this.mapPoint.lon !== this.stats[i].lon) {
            this.mapPoint = this.stats[i]
            const pointA = new this.google.maps.LatLng(parseFloat(this.stats[i].lat), parseFloat(this.stats[i].lon))
            const distance = 20 // in meters
            let bearing = parseFloat(this.stats[i].heading)
            let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            const lineSymbol = {
              path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            }

            this.directionPath = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#FA86C4',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }

            bearing = parseFloat(this.stats[i].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourse = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }

          this.videoSeries[0].data.push(this.stats[i].mic)
          this.videoSeries[1].data.push(this.stats[i].accelUserX)
          this.videoSeries[2].data.push(this.stats[i].accelUserY)
          this.videoSeries[3].data.push(this.stats[i].accelUserZ)
          this.videoSeries[4].data.push(this.stats[i].gyroX)
          this.videoSeries[5].data.push(this.stats[i].gyroY)
          this.videoSeries[6].data.push(this.stats[i].gyroZ)
          this.videoSeries[7].data.push(this.stats[i].speed)
          this.videoChartOptions.xaxis.categories.push(this.stats[i].timestamp)

          i = i + 1

          if (i >= this.stats.length) {
            if (this.playPauseChartTimeout) {
              clearInterval(this.playPauseChartTimeout)
              this.playChart = false

              this.videoSeries[0].data = []
              this.videoSeries[1].data = []
              this.videoSeries[2].data = []
              this.videoSeries[3].data = []
              this.videoSeries[4].data = []
              this.videoSeries[5].data = []
              this.videoSeries[6].data = []
              this.videoSeries[7].data = []
              this.videoChartOptions.xaxis.categories = []
            }
          }
        }, 34)

      } else {
        if (this.playPauseChartTimeout) {
          clearInterval(this.playPauseChartTimeout)
        }

        this.playChart = false

        this.videoSeries[0].data = []
        this.videoSeries[1].data = []
        this.videoSeries[2].data = []
        this.videoSeries[3].data = []
        this.videoSeries[4].data = []
        this.videoSeries[5].data = []
        this.videoSeries[6].data = []
        this.videoSeries[7].data = []
        this.videoChartOptions.xaxis.categories = []
      }
    },
    openMarker(id) {
      this.openedMarkerID = id
    },
    convertHexToRGBA (hexCode, opacity = 1) {
        let hex = hexCode.replace('#', '')

        if (hex.length === 3) {
            hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
        }

        const r = parseInt(hex.substring(0, 2), 16)
        const g = parseInt(hex.substring(2, 4), 16)
        const b = parseInt(hex.substring(4, 6), 16)

        /* Backward compatibility for whole number based opacity values. */
        if (opacity > 1 && opacity <= 100) {
            opacity = opacity / 100
        }

        this.backgroundColorR = r
        this.backgroundColorG = g
        this.backgroundColorB = b
        this.backgroundColorO = opacity * 255
    },
    show3D () {
      this.modelLoading = true
      document.getElementById('viewer').innerHTML = ''
      try {
        // get the parent element of the viewer
        const parentDiv = document.getElementById('viewer')
        const viewer = new OV.EmbeddedViewer(parentDiv, {
          camera : new OV.Camera (
            new OV.Coord3D (this.positionX, this.positionY, this.positionZ),
            new OV.Coord3D (0.0, 0.0, 0.0),
            new OV.Coord3D (this.upX, this.upY, this.upZ),
            this.fov
          ),
          // projectionMode: 2,
          // navigationMode: 2,
          backgroundColor : new OV.RGBAColor (this.backgroundColorR, this.backgroundColorG, this.backgroundColorB, this.backgroundColorO),
          defaultColor : new OV.RGBColor (200, 200, 200),
          edgeSettings : new OV.EdgeSettings (this.showEdge, new OV.RGBColor (0, 0, 0), 1),
          onModelLoaded: this.modelLoaded
        })
        // load a model providing model urls
        viewer.LoadModelFromUrlList([
          this.fileLink.obj,
          this.fileLink.mtl,
          this.fileLink.jpg
        ])

        this.viewer = viewer.GetViewer()
      } catch (error) {
        console.log(error)
      }
    },
    applySettings () {
      // Restore to exact view
      this.positionX = this.viewer.camera.position.x
      this.positionY = this.viewer.camera.position.y
      this.positionZ = this.viewer.camera.position.z
      this.upX = this.viewer.camera.up.x
      this.upY = this.viewer.camera.up.y
      this.upZ = this.viewer.camera.up.z
      this.fov = this.viewer.camera.fov
      this.convertHexToRGBA(this.backgroundColor)

      this.viewer.Destroy()
      document.getElementById('viewer').innerHTML = ''
      this.showSettingsDropdown = false
      this.show3D()
    },
    modelLoaded () {
      this.modelLoading = false
    },
    configureFile: async function () {
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const payload = {}
        if (this.splats_status) {
          payload.splats_status = 'pending'
        }
        // Edit a session
        await axios.put(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files/${this.fileId}`, payload, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'File configured successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.loader = this.$loading.show()
        // Load file
        const file = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files/${this.fileId}`, { headers })
        this.file = file.data.file
        this.loader.hide()
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files/${this.fileId}` } })
      }
    },
    retryConfigure: async function (option) {
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const payload = {}
        payload[option] = 'pending'
        // Edit a session
        await axios.put(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/images/${this.fileId}`, payload, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'File retry configured!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })
        this.loader = this.$loading.show()
        // Load image
        const image = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/images/${this.fileId}`, { headers })
        this.image = image.data.image
        this.loader.hide()
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files/${this.fileId}` } })
      }
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: right;
    text-align: -webkit-right;
    padding-top: 1rem;
  }

  .download-icon:hover {
    color: var(--va-primary);
  }

  video {
    width: 80%;
    height: 80%;
    object-fit: cover;
    position: absolute;
  }

  .gm-fullscreen-control {
    background-color: var(--va-white) !important;
  }

  .gmnoprint > .gmnoprint > div {
    background-color: var(--va-white) !important;
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .va-alert {
    background-color: var(--va-white) !important;;
  }

  .vue-map > div > div > div > div > div > div > div > div > div > div > div {
    color: var(--va-pure-dark) !important;
  }

  .gm-style-mtc > button {
    background-color: var(--va-white) !important;
    color: var(--va-dark) !important;
  }

  .spinner-border {
    --bs-spinner-width: 1.5rem;
    --bs-spinner-height: 1.5rem;
  }
</style>
