<template>
  <div class="viewer-container">
    <!-- This container will hold the viewer's canvas -->
    <div ref="canvas" class="viewer-canvas"></div>
  </div>
</template>

<script>
import * as GaussianSplats3D from '@mkkellogg/gaussian-splats-3d'

export default {
  name: 'Viewer2-template',
  props: {
    plyUrl: {
      type: String,
      required: true
    }
  },
  mounted() {
    const canvas = this.$refs.canvas
    if (canvas) {
      // Pass the container element so it renders inside our designated element.
      const viewer = new GaussianSplats3D.Viewer({
        rootElement: canvas,
        cameraUp: [0, -1, 0],
        initialCameraPosition: [1, 0, 0],
        initialCameraLookAt: [0, 1, 0],
        halfPrecisionCovariancesOnGPU: false,
        antialiased: false,
        sphericalHarmonicsDegree: 0,
        sharedMemoryForWorkers: false
      })

      viewer.addSplatScene(this.plyUrl, {
        format: 2,
        splatAlphaRemovalThreshold: 1
      })
      .then(() => {
        viewer.start()
        // Adjust the generated canvas height.
        const renderedCanvas = canvas.querySelector('canvas')
        if (renderedCanvas) {
          renderedCanvas.style.height = '500px'
        }
      })
      .catch((err) => {
        console.error('Error initializing GaussianSplatsViewer:', err)
      })
    }
  }
}
</script>

<style scoped>
.viewer-container {
  position: relative;
  width: 60%;
  height: 60%;
  min-height: 500px;
  margin: auto;
  border: 1px solid black;
}

.viewer-canvas {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
